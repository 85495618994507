import Vue from 'vue';
import VueRouter from 'vue-router';

import APP_ROUTES from '@/constants/app-routes';
import store from '@/store';

Vue.use(VueRouter);

const DEFAULT_TITLE = 'Talent Infusion';

let routes = [
    {
        path: '/',
        component: () => {
            return import('@/components/Auth/index.vue');
        },
        children: [
            {
                path: '/',
                name: 'home',
                redirect: 'signup',
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                component: () => {
                    return import('@/components/ForgotPassword.vue');
                },
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                component: () => {
                    return import('@/components/ResetPassword.vue');
                },
            },
            {
                path: 'reset-confirmation',
                name: 'reset-confirmation',
                component: () => {
                    return import('@/components/ResetConfirmation.vue');
                },
            },
        ],
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => {
            return import('@/components/Onboarding/Enterprise/Signup.vue');
        },
        meta: {
            alreadyLoggedIn: true,
        },
    },
    {
        path: '/verification-code',
        name: 'verification-code',
        component: () => {
            return import('@/components/Onboarding/Enterprise/OTPVerification.vue');
        },
    },
    {
        path: '/signin',
        name: 'signin',
        component: () => {
            return import('@/components/Signin.vue');
        },
        meta: {
            alreadyLoggedIn: true,
        },
    },
    {
        path: '/account',
        component: () => {
            return import('@/components/Account/index.vue');
        },
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'talent/details/:id',
                name: 'account-details',
                component: () => {
                    return import('@/components/Account/Search/TalentDetailsPage.vue');
                },
            },
            {
                path: '/',
                redirect: 'dashboard',
                component: () => {
                    return import('@/components/Account/TemplateWithFilter.vue');
                },
                children: [
                    {
                        path: 'dashboard',
                        name: 'account-dashboard',
                        component: () => {
                            return import('@/components/Account/Search/index.vue');
                        },
                    },
                    {
                        path: 'search',
                        name: 'account-search',
                        component: () => {
                            return import('@/components/Account/Search/index.vue');
                        },
                    },
                ],
            },
            {
                path: 'settings',
                component: () => {
                    return import('@/components/Account/Settings/index.vue');
                },
                children: [
                    {
                        path: '/',
                        name: 'account-settings',
                        component: () => {
                            return import('@/components/Account/Settings/Edit.vue');
                        },
                    },
                    {
                        path: 'payment-method',
                        name: 'account-settings-payment-method',
                        component: () => {
                            return import('@/components/Account/Settings/Subscription.vue');
                        },
                    },
                    {
                        path: 'team',
                        name: 'account-settings-team',
                        component: () => {
                            return import('@/components/Account/Settings/TeamPage/index.vue');
                        },
                        meta: {
                            requiresAdmin: true,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/jobs',
        component: () => {
            return import('@/components/Jobs/index.vue');
        },
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/',
                name: 'jobs',
                component: () => {
                    return import('@/components/Jobs/Jobs.vue');
                },
            },
            {
                path: 'details/:id',
                name: 'job-details',
                component: () => {
                    return import('@/components/Jobs/Jobs.vue');
                },
            },
            {
                path: 'archived',
                name: 'archived-jobs',
                component: () => {
                    return import('@/components/Jobs/Archived/index.vue');
                },
            },
            {
                path: 'archived/details/:id',
                name: 'archived-job-details',
                component: () => {
                    return import('@/components/Jobs/Archived/index.vue');
                },
            },
            {
                path: 'add',
                name: 'add-job',
                component: () => {
                    return import('@/components/Jobs/Add/index.vue');
                },
            },
        ],
    },
    {
        path: '/talent/profile',
        component: () => {
            return import('@/components/Profile/Talent/index.vue');
        },
        meta: {
            alreadyLoggedIn: true,
        },
    },
    {
        path: APP_ROUTES.enterpriseOnboardingPlans,
        meta: {
            requiresAuth: true,
        },
        component: () => {
            return import('@/components/Onboarding/Enterprise/Plans.vue');
        },
    },
];
routes = routes.concat([
    {
        path: '/talent/onboarding',
        name: 'onboarding',
        component: () => {
            return import('@/components/Onboarding/Signup.vue');
        },
    },
    {
        path: '/talent/activation/activate',
        name: 'talent-activation',
        component: () => {
            return import('@/components/Onboarding/TalentActivation.vue');
        },
    },
    {
        path: '/talent/activation/get-started',
        name: 'activation-get-started',
        component: () => {
            return import('@/components/Onboarding/ActivationEntry.vue');
        },
    },
    {
        path: '/talent/activation/info',
        name: 'activation-info',
        component: () => {
            return import('@/components/Onboarding/ActivationInfo.vue');
        },
    },
    {
        path: '/talent/onboarding/uploadresume',
        name: 'onboarding-uploadresume',
        component: () => {
            return import('@/components/Onboarding/UploadResume.vue');
        },
    },
    {
        path: '/talent/onboarding/createaccount',
        name: 'onboarding-create-account',
        component: () => {
            return import('@/components/Onboarding/AccountCreation.vue');
        },
    },
    {
        path: '/talent/onboarding/verificationcode',
        name: 'onboarding-verification-code',
        component: () => {
            return import('@/components/Onboarding/VerificationCode.vue');
        },
    },
    {
        path: '/talent/login',
        name: 'onboarding-login',
        component: () => {
            return import('@/components/Onboarding/Login.vue');
        },
    },
    {
        path: '/talent/forgot-password',
        name: 'onboarding-forgot-password',
        component: () => {
            return import('@/components/Onboarding/ForgotPassword.vue');
        },
    },
    {
        path: '/talent/codereset',
        name: 'onboarding-verification-code',
        component: () => {
            return import('@/components/Onboarding/CodeReset.vue');
        },
    },
    {
        path: '/talent/passwordreset',
        name: 'onboarding-password-reset',
        component: () => {
            return import('@/components/Onboarding/PasswordReset.vue');
        },
    },
]);
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            };
        }

        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        };
    },
});

router.beforeEach((to, from, next) => {
    if (to.name === from.name) {
        next();
        return;
    }

    if (
        to.matched.some((record) => {
            return record.meta.requiresAuth;
        }) &&
        !store.getters['app/user']
    ) {
        next({
            name: 'signin',
        });
        return;
    }

    if (to.meta.requiresAdmin && store.getters['app/user'].user_type !== 'admin') {
        next({
            path: from.path,
        });
        return;
    }

    if (
        to.matched.some((record) => {
            return record.meta.alreadyLoggedIn;
        }) &&
        store.getters['app/user']
    ) {
        next({
            name: 'account-dashboard',
        });
        return;
    }

    const { user } = store.state.app;
    if (
        user &&
        user.subscription &&
        user.boss_mode === false &&
        !to.name.includes('settings') &&
        !to.query.sendInvoice
    ) {
        next({
            name: 'account-settings-payment-method',
            query: { payment: false },
        });
        return;
    }

    next();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;
