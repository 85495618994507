import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter';

const auth = axios.create({
    baseURL: process.env.VUE_APP_API,
});

const initialTokensDetails = {
    availableTokens: 0,
    isLessThanTenPercent: false,
    tokenResetDate: '',
    totalTokens: 0,
    usedTokens: 0,
};

const initialState = {
    token: '',
    talentUserId: null,
    talentToken: '',
    talentResultCount: null,
    user: null,
    lastTime: new Date(),
    viewedTalentIds: [],
    forgotEmail: '',
    resetPasswordToken: '',
    cannyToken: null,
    talenResumeUpload: null,
    placesAPISessionToken: null,
    filtersSidebarExpanded: false,
    filtersCount: 0,
    filterOptions: {},
    tokensDetails: initialTokensDetails,
    productDetails: null,
    blockedAccountStatuses: [
        'incomplete',
        'incomplete_expired',
        'trialing',
        'past_due',
        'canceled',
        'unpaid',
        'paused',
    ],
    invoiceDetails: null,
};

const moduleGetters = {
    isTalentViewed: ({ viewedTalentIds }) => {
        return (id) => {
            return viewedTalentIds.includes(id);
        };
    },
    talentToken: ({ talentToken }) => {
        return talentToken;
    },
    talentUserId: ({ talentUserId }) => {
        return talentUserId;
    },
    lastTime: ({ lastTime }) => {
        return new Date(lastTime);
    },
    token: ({ token }) => {
        return token;
    },
    cannyToken: ({ cannyToken }) => {
        return cannyToken;
    },
    talentResultCount: ({ talentResultCount }) => {
        return talentResultCount;
    },
    talenResumeUpload: ({ talenResumeUpload }) => {
        return talenResumeUpload;
    },
    filtersSidebarExpanded: ({ filtersSidebarExpanded }) => {
        return filtersSidebarExpanded;
    },
    filtersCount: ({ filtersCount }) => {
        return filtersCount;
    },
    user: ({ user }) => {
        if (!user) {
            return null;
        }

        const { first_name: firstName, last_name: lastName, email } = user;
        let avatar = '';
        let fullName = '';

        if (firstName && lastName) {
            fullName = `${firstName} ${lastName}`;
        } else if (firstName) {
            fullName = firstName;
        } else if (lastName) {
            fullName = lastName;
        }

        avatar = fullName || email;

        return { ...user, avatar, fullName };
    },
    placesAPISessionToken: ({ placesAPISessionToken }) => {
        return placesAPISessionToken;
    },
    filterOptions: ({ filterOptions }) => {
        return filterOptions;
    },
    subscription: ({ user }) => {
        return user.subscription ?? {};
    },
    tokensInfo: ({ tokensInfo }) => {
        return tokensInfo;
    },
    productDetails: ({ productDetails }) => {
        return productDetails;
    },
    tokensDetails: ({ tokensDetails }) => {
        return tokensDetails;
    },
    availableTokens: ({ tokensDetails }) => {
        return tokensDetails.availableTokens;
    },
    blockedAccountStatuses: ({ blockedAccountStatuses }) => {
        return blockedAccountStatuses;
    },
    invoiceDetails: ({ invoiceDetails }) => {
        return invoiceDetails;
    },
};

const mutations = {
    INSERT_TALENT_ID_INTO_VIEWED_LIST(state, payload) {
        if (state.viewedTalentIds.includes(payload)) {
            return;
        }

        state.viewedTalentIds.push(payload);
    },
    SET_LAST_TIME(state, payload) {
        const lastTime = new Date(state.lastTime);
        const millisecondsDiff = payload.getTime() - lastTime.getTime();
        const daysDiff = Math.round(millisecondsDiff / (24 * 60 * 60 * 60));

        if (daysDiff < 1) {
            return;
        }

        state.lastTime = payload;
    },
    SET_TOKEN(state, payload) {
        state.token = payload;
    },
    SET_TALENT_TOKEN(state, payload) {
        state.talentToken = payload;
    },
    SET_TALENT_USER_ID(state, payload) {
        state.talentUserId = payload;
    },
    SET_CANNY_TOKEN(state, payload) {
        state.cannyToken = payload;
    },
    SET_TALENT_RESULT_COUNT(state, payload) {
        state.talentResultCount = payload;
    },
    SET_TALENT_RESUME_UPLOAD(state, payload) {
        state.talenResumeUpload = payload;
    },
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_EMAIL_FOR_FORGOT_PASSWORD(state, payload) {
        state.forgotEmail = payload;
    },
    SET_RESET_PASSWORD_TOKEN(state, payload) {
        state.resetPasswordToken = payload;
    },
    SET_PLACES_API_SESSION_TOKEN(state, payload) {
        state.placesAPISessionToken = payload;
    },
    SET_FILTERS_SIDEBAR_EXPANDED(state, payload) {
        state.filtersSidebarExpanded = payload;
    },
    SET_FILTERS_COUNT(state, payload) {
        state.filtersCount = payload;
    },
    SET_FILTER_OPTIONS(state, payload) {
        state.filterOptions = payload;
    },
    SET_TOKENS_DETAILS(state, payload) {
        state.tokensDetails = payload;
    },
    SET_PRODUCT_DETAILS(state, payload) {
        state.productDetails = payload;
    },
    SET_INVOICE_DETAILS(state, payload) {
        state.invoiceDetails = payload;
    },
};

const actions = {
    async FETCH_USER({ commit, state }) {
        await auth
            .get(`/user/${state.user._id}`, {
                headers: { Authorization: `Bearer ${state.token}` },
            })
            .then(({ data }) => {
                let id = null;
                if (data.subscription !== undefined) {
                    id = data.subscription._id;
                }

                const fetchData = async () => {
                    const response = await auth.get(`/subscription/${id}/tokens`, {
                        headers: {
                            Authorization: `Bearer ${state.token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    commit('SET_TOKENS_DETAILS', response.data);
                };

                if (id) {
                    fetchData();
                }

                commit('SET_USER', data);
            })
            .catch(() => {
                commit('SET_USER', null);
            });
    },
    async FETCH_INVOICE_DETAILS({ commit, state }) {
        const response = await auth.get('invoice', {
            headers: {
                Authorization: `Bearer ${state.token}`,
            },
        });
        commit('SET_INVOICE_DETAILS', response.data);
    },
    SET_LAST_TIME({ commit }) {
        commit('SET_LAST_TIME', new Date());
    },
    SET_TOKEN({ commit }, payload) {
        commit('SET_TOKEN', payload);
    },
    SET_TALENT_TOKEN({ commit }, payload) {
        commit('SET_TALENT_TOKEN', payload);
    },
    SET_TALENT_USER_ID({ commit }, payload) {
        commit('SET_TALENT_USER_ID', payload);
    },
    SET_CANNY_TOKEN({ commit }, payload) {
        commit('SET_CANNY_TOKEN', payload);
    },
    SET_TALENT_RESULT_COUNT({ commit }, payload) {
        commit('SET_TALENT_RESULT_COUNT', payload);
    },
    SET_TALENT_RESUME_UPLOAD({ commit }, payload) {
        commit('SET_TALENT_RESUME_UPLOAD', payload);
    },
    SET_EMAIL_FOR_FORGOT_PASSWORD({ commit }, payload) {
        commit('SET_EMAIL_FOR_FORGOT_PASSWORD', payload);
    },
    SET_RESET_PASSWORD_TOKEN({ commit }, payload) {
        commit('SET_RESET_PASSWORD_TOKEN', payload);
    },
    SET_USER({ commit }, payload) {
        commit('SET_USER', payload);
    },
    SIGN_OUT({ commit }) {
        commit('SET_USER', null);
        commit('SET_TOKENS_DETAILS', initialTokensDetails);
        commit('SET_PRODUCT_DETAILS', null);
        commit('SET_INVOICE_DETAILS', null);
        GlobalEventEmitter.$emit('navigate', { name: 'signin' });
    },
    UPDATE_USER({ dispatch, getters }, payload) {
        const { token, user } = getters;

        if (!token || !user) {
            return undefined;
        }

        return new Promise((resolve, reject) => {
            auth.put(`/user/${user._id}`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then(({ data }) => {
                    const { token: newAuthToken, updatedUser } = data;

                    dispatch('SET_TOKEN', newAuthToken);
                    dispatch('SET_USER', {
                        ...user,
                        ...updatedUser,
                    });

                    return resolve({
                        ...user,
                        ...updatedUser,
                    });
                })
                .catch((ex) => {
                    dispatch('SET_TOKEN', '');
                    dispatch('SET_USER', null);

                    return reject(ex);
                });
        });
    },
    VIEWED_TALENT({ commit }, payload) {
        commit('INSERT_TALENT_ID_INTO_VIEWED_LIST', payload);
    },
    GENERATE_PLACES_API_SESSION_TOKEN({ commit }) {
        commit('SET_PLACES_API_SESSION_TOKEN', uuidv4());
    },
    INVALIDATE_PLACES_API_SESSION_TOKEN({ commit }) {
        commit('SET_PLACES_API_SESSION_TOKEN', null);
    },
    SET_FILTERS_SIDEBAR_EXPANDED({ commit }, payload) {
        commit('SET_FILTERS_SIDEBAR_EXPANDED', payload);
    },
    SET_FILTERS_COUNT({ commit }, payload) {
        commit('SET_FILTERS_COUNT', payload);
    },
    SET_TOKENS_DETAILS({ commit }, payload) {
        commit('SET_TOKENS_DETAILS', payload);
    },
    SET_FILTER_OPTIONS({ commit }, payload) {
        commit('SET_FILTER_OPTIONS', payload);
    },
    SET_PRODUCT_DETAILS({ commit }, payload) {
        commit('SET_PRODUCT_DETAILS', payload);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters: moduleGetters,
    mutations,
    actions,
};
